<template></template>

<script>
import { openMiniProgram } from '@/utils/nwechat.js'
// 该页面用于打开小程序
export default {
  data() {
    return {}
  },
  async mounted() {
    const { appid, path } = this.$route.query
    let newPath = path
    const pathIndex = newPath.indexOf('?')
    if (pathIndex === -1) {
      if (newPath.indexOf('.html') === -1) {
        newPath = `${newPath}.html`
      }
    } else {
      if (newPath.indexOf('.html') === -1) {
        let pathArray = newPath.split('')
        const index = pathArray.indexOf('?')
        pathArray.splice(index, 0, '.html')
        newPath = pathArray.join('')
      }
    }
    await openMiniProgram({ appid, path: newPath })
    wx.closeWindow()
  }
}
</script>

<style></style>
